<template>
  <div v-show="dataLoaded">
    <v-row>
      <v-col cols="12" class="pt-0 mt-3">
        <v-btn
          small
          color="primary"
          class="white--text px-4"
          @click="dialog = true"
        >
          <v-icon left small>mdi-plus</v-icon>Добавить
        </v-btn>

        <v-card
          v-show="eventPlaces.list.length"
          class="table-card mt-4"
        >
          <v-data-table
            ref="eventPlacesTable"
            class="base-table"
            fixed-header
            hide-default-footer
            :headers="headers"
            :items="eventPlaces.list"
            :items-per-page="-1"
          >
            <template #item.actions="{ item }">
              <v-icon
                class="mr-1 edit-btn"
                color="btnIcon"
                size="22"
                title="Редактировать"
                @click="editEventPlace(item)"
              >
                mdi-pencil-outline
              </v-icon>

              <v-icon
                class="remove-btn"
                color="btnIcon"
                size="22"
                title="Удалить"
                @click="confirmRemoveEventPlace(item)"
              >
                mdi-trash-can-outline
              </v-icon>
            </template>

            <template slot="no-data">
              <div>Таблица пуста</div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <EventPlaceDialog
      :dialog="dialog"
      :eventPlace="selectedItem"
      :loading="eventPlaceLoading"
      @saveEventPlace="saveEventPlace"
      @closeDialog="closeDialog"
    />

    <DeleteConfirmDialog
      :dialog="confirmDialog"
      :loading="deleteLoading"
      :message="'Вы уверены, что хотите удалить площадку проведения занятий?'"
      @confirm="removeEventPlace"
      @closeConfirmModal="closeConfirmDialog"
    />
  </div>
</template>

<script>
import { eventPlaceApi } from '@/api'
import { mapGetters } from 'vuex'
import { EventPlaces } from '@/models'
import EventPlaceDialog from '@/components/department/eventPlaces/dialogs/EventPlace'
import DeleteConfirmDialog from '@/components/dialogs/DeleteConfirm'

export default {
  name: 'EventPlacesTable',

  components: {
    EventPlaceDialog,
    DeleteConfirmDialog
  },

  async created() {
    await this.getEventPlaces()
    this.dataLoaded = true
    this.$emit('dataLoaded')
  },

  mounted() {
    this.setTableHeight()
    window.addEventListener("resize", this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener("resize", this.setTableHeight)
  },

  data: () => ({
    dataLoaded: false,
    headers: [
      { text: 'Название', value: 'name', sortable: false, align: 'left' },
      { text: 'Адрес', value: 'fullAddress', sortable: false, align: 'left' },
      { text: 'Действия', value: 'actions', sortable: false, align: 'right', width: '100px' }
    ],
    selectedItem: null,
    dialog: false,
    confirmDialog: false,
    eventPlaceLoading: false,
    deleteLoading: false,

    eventPlaces: new EventPlaces()
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('department', ['departmentId']),
    ...mapGetters('cycle', ['cycleId'])
  },

  methods: {
    async getEventPlaces() {
      try {
        const data = await eventPlaceApi.getEventPlaces(this.departmentId)
        data.length && this.eventPlaces.buildFromAPI(data)
      } catch (e) {
        console.log(e.status)
      }
    },

    async saveEventPlace(eventPlace) {
      try {
        this.eventPlaceLoading = true

        const data = eventPlace.id
          ? await eventPlaceApi.updateEventPlace(this.departmentId, eventPlace.getForAPI())
          : await eventPlaceApi.addEventPlace(this.departmentId, eventPlace.getForAPI())

        eventPlace.id ? this.eventPlaces.updateEventPlace(data) : this.eventPlaces.addEventPlace(data)
        this.closeDialog()
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения площадки проведения занятий')
      } finally {
        this.eventPlaceLoading = false
      }
    },

    async removeEventPlace() {
      try {
        if (this.selectedItem.id) {
          this.deleteLoading = true

          await eventPlaceApi.removeEventPlace(this.departmentId, this.selectedItem.id)
          this.eventPlaces.removeEventPlace(this.selectedItem.id)
          this.closeConfirmDialog()
        }
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка удаления площадки проведения занятий')
      } finally {
        this.deleteLoading = false
      }
    },

    setTableHeight() {
      const otherElements = 299 + this.techMessageHeight

      if (this.$refs?.eventPlacesTable) {
        this.$refs.eventPlacesTable.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
      }
    },

    editEventPlace(item) {
      this.selectedItem = item
      this.dialog = true
    },

    confirmRemoveEventPlace(item) {
      this.selectedItem = item
      this.confirmDialog = true
    },

    closeDialog() {
      this.dialog = false
      this.selectedItem = null
    },

    closeConfirmDialog() {
      this.confirmDialog = false
      this.selectedItem = null
    }
  }
}
</script>