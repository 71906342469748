<template>
	<v-row justify="center">
		<v-dialog :value="dialog" max-width="650" @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="headline accent--text pb-4">
          {{ isEdit ? 'Редактирование площадки проведения занятий' : 'Добавление площадки проведения занятий' }}
        </v-card-title>

				<v-card-text outlined tile class="border-top">
					<v-container>
						<v-row>
							<v-col cols="12" class="pb-0 pt-5 px-0">
                <v-textarea
                  v-model="eventPlaceLocal.name"
                  label="Название"
                  no-resize
                  auto-grow
                  rows="1"
                  :error-messages="errorMessages.name"
                  @input="$v.eventPlaceLocal.name.$touch()"
                  @blur="$v.eventPlaceLocal.name.$touch()"
                />

                <v-textarea
                  v-model="eventPlaceLocal.address"
                  label="Адрес"
                  no-resize
                  auto-grow
                  rows="1"
                  :error-messages="errorMessages.address"
                  @input="$v.eventPlaceLocal.address.$touch()"
                  @blur="$v.eventPlaceLocal.address.$touch()"
                />

                <v-text-field
                  v-model="eventPlaceLocal.floor"
                  label="Этаж"
                  v-mask="'##'"
                  :error-messages="errorMessages.floor"
                  @input="$v.eventPlaceLocal.floor.$touch()"
                  @blur="$v.eventPlaceLocal.floor.$touch()"
                />

                <v-textarea
                  v-model="eventPlaceLocal.place"
                  label="Аудитория"
                  no-resize
                  auto-grow
                  rows="1"
                  :error-messages="errorMessages.place"
                  @input="$v.eventPlaceLocal.place.$touch()"
                  @blur="$v.eventPlaceLocal.place.$touch()"
                />
              </v-col>
            </v-row>
					</v-container>
				</v-card-text>				

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Отмена</v-btn>

          <v-btn
            :disabled="$v.$invalid"
						:loading="loading"
            color="success"
            text
            @click="saveEventPlace"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import { required, maxLength } from 'vuelidate/lib/validators'
import { regexFrom0To99, setValidateMessages } from '@/scripts/validation'
import { EventPlace } from '@/models'

export default {
  name: 'EventPlaceDialog',

	props: {
		dialog: Boolean,
    eventPlace: Object,
		loading: Boolean
	},

	data: () => ({
    eventPlaceLocal: new EventPlace()
	}),

  validations() {
    return {
      eventPlaceLocal: {
        name: { required, maxLength: maxLength(300) },
        address: { required, maxLength: maxLength(1000) },
        floor: { required, regexFrom0To99 },
        place: { required, maxLength: maxLength(100) }
      }
    }
  },

	computed: {
    isEdit() {
      return this.eventPlaceLocal?.id
    },

    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.eventPlaceLocal.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.eventPlaceLocal[key])
      })

      return allErrors
    }
	},

	methods: {
		saveEventPlace() {
      this.$emit('saveEventPlace', _cloneDeep(this.eventPlaceLocal))
    },

    resetModal() {
      this.$v.$reset()
      this.eventPlaceLocal = new EventPlace()
    },

    closeDialog() {
      this.resetModal()
			this.$emit('closeDialog')
		}
	},

  watch: {
    eventPlace(val) {
      this.eventPlaceLocal = val ? EventPlace.buildFromAPI(val) : new EventPlace()
    },

    dialog(val) {
      !val && this.resetModal()
    }
  }
}
</script>